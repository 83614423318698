import React, { useEffect } from 'react'
import LandingPage from './LandingPage'
import AboutUs from './AboutUs'
import OurProducts from './OurProducts'
import NeedAnyHelp from './NeedAnyHelp'
import WhyChooseUs from './WhyChooseUs'
import OurAffiliations from './OurAffiliations'
import EnquireNow from './EnquireNow'

export default function HomePage() {
  useEffect(()=>{
    window.scroll(0,0);
  },[]);
  return (
    <div className='py-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex'>
        <LandingPage />
        <AboutUs />
        <OurProducts />
        <NeedAnyHelp />
        <WhyChooseUs />
        <OurAffiliations />
        <EnquireNow />
    </div>  
  )
}
