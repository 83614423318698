import React from "react";
import {motion} from "framer-motion";

export default function WhyChooseUs() {
  return (
    <div className="mt-5 py-5 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
      <div className="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 row px-0 display-flex-align-items-top">
        <div className="col-12 col-sm-12 col-md-12 col-xl-6 col-lg-6 row px-0 display-flex">
          <div className="w-50 px-3 display-flex">
            <motion.img
            
            initial={{y:-200, opacity:0}}
            whileInView={{y:0, opacity:1}}
            transition={{duration:1.5, damping:200}}
              src={"https://soltechindia.co.in/images/why-choose-us-1.jpg"}
              alt=""
              className="w-100 o-fit-cover"
            />
          </div>
          <div className="w-50 px-3 display-flex">
            <motion.img
            initial={{y:200, opacity:0}}
            whileInView={{y:0, opacity:1}}
            transition={{duration:1.5, damping:200}}
              src={"https://soltechindia.co.in/images/why-choose-us-2.jpg"}
              alt=""
              className="w-100 o-fit-cover"
            />
          </div>
        </div>

        <motion.div
        initial={{opacity:0}}
        whileInView={{opacity:1}}
        transition={{duration:1.5 , damping:200}}
        className="pt-4 col-12 col-sm-12 col-md-12 col-xl-6 col-lg-6 row px-5 display-flex">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
            <div className="color-theme-orange w-100 px-0 m-0 display-flex text-center fs-medium-css fw-bold">
              Why Choose Us ? 
            </div>

            <div className="w-100 px-0 mt-3 display-flex-start fs-slight-small-css text-center fw-normal">
            Choose us for premium industrial oils and greases, unmatched quality, expert solutions, and reliable performance for all machinery needs
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
              <div className="mt-5 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-align-items-top">
                <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 row px-0 display-flex">
                  <span className="contact-us-address-icon rounded-circle display-flex">
                  <i class="fa-solid fa-flag-checkered"></i>
                  </span>
                </div>

                <div className="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 row px-0 display-flex">
                  <div className="fw-bold col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                  Quality Products
                  </div>
                  <div className="fw-normal mt-1 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start fs-slight-small-css">
                  Our oils and greases ensure long-lasting, reliable performance in demanding industrial applications.
                  </div>
                </div>
              </div>

              <div className="mt-5 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-align-items-top">
                <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 row px-0 display-flex">
                  <span className="contact-us-address-icon rounded-circle display-flex">
                  <i class="fa-solid fa-flag-checkered"></i>
                  </span>
                </div>

                <div className="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 row px-0 display-flex">
                  <div className="fw-bold col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                  Expertise
                  </div>
                  <div className="fw-normal mt-`2` col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start fs-slight-small-css">
                  With years of experience, we provide tailored solutions for various industry needs.
                  </div>
                </div>
              </div>

              <div className="mt-5 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-align-items-top">
                <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 row px-0 display-flex">
                  <span className="contact-us-address-icon rounded-circle display-flex">
                  <i class="fa-solid fa-flag-checkered"></i>
                  </span>
                </div>

                <div className="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 row px-0 display-flex">
                  <div className="fw-bold col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                  Sustainability
                  </div>
                  <div className="fw-normal mt-1 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start fs-slight-small-css">
                  We prioritize eco-friendly manufacturing processes for environmentally responsible products.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
