import React from "react";
import { motion } from "framer-motion";
import { Carousel } from "primereact/carousel";

export default function OurAffiliations() {

  const clients = [
    {name:"PRAGATI PRESS TOOLS INDIA PVT. LTD", image:"pragati press.png"},
    {name:"ADROIT INTEGRATED SYSTEMS AND AUTOMATION PVT LTD", image:"adroit.jpg"},
    {name:"AMIT ENTERPRISES HOUSING LTD", image:"amit.jpg"},
    {name:"CTSTRENGTH INDIA LLP", image:"cstrength.jpg"},
    // {name:"BHAVIKA BIOCHEM", image:"logo-placeholder.jpg"},
    {name:"DELMAN POLYMER PVT. LTD", image:"delman.jpg"},
    {name:"DELTA SMALL TOOLS AND ENGINEERING PVT. LTD", image:"delta.jpg"},
    {name:"DYNAMIC CRANE ENGINEERS PVT. LTD", image:"dynamic.jpg"},
    {name:"EXOTIC FRUIT PVT. LTD", image:"exotic fruits.jpg"},
    {name:"K & K ENGINEERS", image:"k&k.png"},
    {name:"JAGADAMBA AUTO COMPONENTS LTD", image:"jagdamba.png"},
    {name:"MANAR TOOLS PVT. LTD", image:"manar.png"},
    {name:"MANISHA ENGINEERING WORKS", image:"manisha.png"},
    {name:"PLANETTO TOOLTECH PVT. LTD", image:"planetto.jpg"},
    {name:"RAMESHWER ENTERPRISES", image:"Rameshwar-Enterprises.jpeg"},
    {name:"ACCURATE ENGINEERING COMPANY PVT. LTD", image:"accurate.png"},
    {name:"OMESA ENGINEERS & EXPORTS PVT. LTD.", image:"omesa.jpg"},
    {name:"SHRINIDHI INNOTECH PVT.LTD", image:"shrinidhi.jpg"},
    {name:"SPACION ENGINEERING INDUSTRIES", image:"spantech.png"},
    {name:"OM STEEL INDUSTRIES", image:"om.jpg"}
  ];

  const responsiveOptions = [
    {
      breakpoint: "2000px",
      numVisible: 4,
      numScroll: 1,
    },
    {
      breakpoint: "1000px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "650px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const productTemplate = (ele) => {
    return (
      <div className="m-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-4 px-0 row display-flex ">
        <div className="mt-5 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-3 row display-flex">
          <div className={`${ele.name==="JAGADAMBA AUTO COMPONENTS LTD" ? "bg-theme-orange" : "bg-white"} client-logo-container display-flex`}>
            <img
              src={"https://soltechindia.co.in/images/"+ ele.image}
              className="client-logo px-0  "
              alt={ele.name}
            />
          </div>
        </div>

        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-4 display-flex mt-2 text-center fs-small-css fw-bold">
          {ele.name}
        </div>
      </div>
    );
  };
  return (
    <motion.div
      initial={{ opacity: 0, y: 200 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 1.5, damping: 200 }}
      className="mt-5 pb-5 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 bg-theme-grey display-flex"
    >
      <div className="mt-4 color-theme-orange w-100 px-0 m-0 display-flex text-center fs-large-css fw-bold">
        CLIENTS
      </div>
      <div className="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 row px-0 display-flex">


        <Carousel 
                value={clients} 
                itemTemplate={productTemplate} 
                numVisible={4} 
                numScroll={1} 
                responsiveOptions={responsiveOptions} 
                circular={true}
                autoplayInterval={4000} 
            /> 
      </div>
    </motion.div>
  );
}
