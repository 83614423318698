import React, { useEffect } from 'react'
import Header from './Header'
import ContactUsContent from './ContactUsContent'
import {motion} from "framer-motion";
// sachinkale@soltechindia.co.in
export default function ContactUsPage() {
  useEffect(()=>{
    window.scroll(0,0);
  },[]);
  return (
    <motion.div
    initial={{opacity:0}}
    whileInView={{opacity:1}}
    transition={{duration:1.5, damping:200}} className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex'>
        <Header title="Contact Us" />

        <ContactUsContent />
    </motion.div>
  )
}
