import React from 'react'
import { Route, Routes } from 'react-router-dom'
import HomePage from './HomePage'
import Navbar from './Navbar'
import StickyButtons from './StickyButtons'
import AboutUsPage from './AboutUsPage'
import Footer from './Footer'
import ContactUsPage from './ContactUsPage'
import ProductsPage from './ProductsPage'
import ProductDetails from './ProductDetails'
import Careers from './Careers'
import AllProductsPage from './AllProductsPage'

export default function UserApp() {
  return (
    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 pb-0 display-flex overflow-hidden-css'>
        <Navbar />
        <Routes>
            <Route path="/" element = {<HomePage />} />
            <Route path="/aboutus" element = {<AboutUsPage />} />
            <Route path="/products" element = {<ProductsPage  />} />
            <Route path="/all-products" element = {<AllProductsPage  />} />
            <Route path="/careers" element = {<Careers  />} />
            <Route path="/productDetails/:productCategory" element = {<ProductDetails />} />
            <Route path="/contactus" element = {<ContactUsPage />} />
        </Routes>

        <Footer />

        <StickyButtons />
    </div>
  )
}
