import React from "react";
import { motion } from "framer-motion";

export default function AboutUs() {
  return (
    <motion.div
    initial={{opacity:0, y:200}}
    whileInView={{opacity:1, y:0}}
    transition={{duration:1.5, damping:200}}
    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-align-items-top">
        <div className="position-relative-css mt-5 col-11 col-sm-11 col-md-11 col-lg-6 col-xl-6 row px-0 display-flex">
          <img src={"https://soltechindia.co.in/images/home-about-us.jpg"} className="w-75 px-0" alt="" />
          <motion.img
            initial={{y:0}}
            animate={{y:[0,70,0]}}
            transition={{duration:5.5, damping:0, repeat:Infinity}}

              src={"https://soltechindia.co.in/images/about-us-top.jpg"}
              className="about-us-top-image px-0"
              alt="No image"
            />
        </div>

        <div className="mt-5 col-11 col-sm-11 col-md-11 col-lg-6 col-xl-6 row px-0 display-flex">
          <div className="color-theme-orange w-100 px-0 m-0 display-flex-start fs-medium-css fw-bold">
            Welcome To
          </div>

          <div className="w-100 px-0 mt-2 display-flex-start fs-larger-css fw-bold">
            SOLT<span className="w-auto px-0 color-theme-orange">E</span>CH
          </div>

          <div className="w-100 px-0 mt-3 display-flex-start fs-slight-small-css fw-normal">
          Ultimate Solution Is A Manufaturer and Wholesaler Of Hydraulic Oil, Lubricant Oil & Gear Oil, Industrial oil Since 2016 In Pune, Maharashtra.
One stop solution for all your industrial Lubricant, MRO, safety & consumables. Delivery all over India with best market competitive rate. No burden of carrying inventory, just shop when you required.
Established in the year 2016, Ultimate Solution Pune is a top player in the category lubricant Oil & industrial variety dealers in the Pune. This well-known establishment acts as a one-stop destination servicing customers both local and from other parts of Pune.
          </div>
        </div>
      </div>
    </motion.div>
  );
}
