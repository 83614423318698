import React from 'react';
import {motion} from "framer-motion";

export default function NeedAnyHelp() {
  return (
    <div className='position-relative height-nah py-5 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex'>
        <motion.div
        // initial={{opacity:0, x:-400}}
        // whileInView={{opacity:1, x:0}}
        // transition={{duration:1.5 , damping:200}}
        className='nah-div-1 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-end'>
        </motion.div>

        <div className='nah-div-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start'>
            <div className='ms-5 nah-div-2-content py-5 px-0 row m-0 display-flex'>
                <motion.div
                initial={{opacity:0, x:400}}
                whileInView={{opacity:1, x:0}}
                transition={{duration:1.5 , damping:200}}
                className='fw-bold fs-large-css w-100 px-0 m-0 display-flex-start'>
                    Need Any Help ?
                </motion.div>

                <motion.div
                initial={{opacity:0, x:400}}
                whileInView={{opacity:1, x:0}}
                transition={{duration:1.5 , damping:200}}
                className='fw-normal fs-medium-css w-100 px-0 m-0 display-flex-start'>
                    +91 - 7276507979
                </motion.div>
            </div>
        </div>

        <motion.button
        initial={{scale:0}}
        whileInView={{scale:[0,1.5,1]}}
        transition={{duration:1.5 , damping:200}}
        className='btn-help nah-btn-center rounded-circle'>
            <i className="fa-solid fa-phone fs-medium-css"></i>
        </motion.button>

        <motion.img src='https://soltechindia.co.in/images/connection.png' alt='' className='nah-connection-image px-0 ' />
    </div>
  )
}
