import React, { useState } from "react";
import Select from "react-select";

export default function ProductsContent(props) {
  const productsData = [
    {
      title: "Cutting Oil",
      para1: `Pioneers in the industry, we offer SOLTECH SMOOTHCUT SUPER 777, SMOOTHCUT SUPER 907, SOLTECH SOLGRIND - 900 and SOLCUT SUPER 50 from India.`,
      para2: ``,
      subProducts: [
        {
          title: "SOLTECH SMOOTHCUT SUPER 777",
          image:
            "https://soltechindia.co.in/images/semi-synthetic-coolant-oil.webp",
          pricePerLitre: "260",
          minimumOrderQuantity: "26",
          features: [
            { key: "Usage/Application", value: "CNC/ VMC/ HMC MACHINE" },
            { key: "Packaging Type", value: "Bucket" },
            { key: "Grade", value: "Industrial" },
            { key: "Pack Sizes LT/KG", value: "26 LTRS / 210 LTRS" },
            { key: "Semi-Synthetic/Mineral", value: "Semi-Synthetic" },
          ],
          subTitle: "MULTIMATERIAL SEMI-SYNTHETIC CUTTING OIL",
          additionalInformation: [
            { key: "Delivery Time", value: "1 WEEK" },
            {
              key: "Packaging Details",
              value: "26 LTRS / 210 LTRS HDEP BUCKET & DRUMS",
            },
          ],
        },
        {
          title: "SMOOTHCUT SUPER 907",
          image:
            "https://soltechindia.co.in/images/semi-synthetic-coolant-oil.webp",
          pricePerLitre: "250",
          minimumOrderQuantity: "26",
          features: [
            { key: "Pack Sizes LT/KG", value: "26 LTRS / 210 LTRS" },
            { key: "Semi-Synthetic/Mineral", value: "Semi-Synthetic" },
            { key: "Usage/Application", value: "CNC / VMC / HMC Machines" },
            { key: "Packaging Type", value: "Bucket" },
            { key: "Grade", value: "Industrial" },
          ],
          subTitle: "Semi-Synthetic Cutting Oil",
          additionalInformation: [{ key: "Delivery Time", value: "1 WEEK" }],
        },
        {
          title: "SOLTECH SOLGRIND - 900",
          image:
            "https://soltechindia.co.in/images/semi-synthetic-coolant-oil.webp",
          pricePerLitre: "175",
          minimumOrderQuantity: "26",
          features: [
            { key: "Unit Pack Size", value: "26 LTRS / 210 LTRS" },
            { key: "Form", value: "SOLTECH" },
            { key: "Color", value: "GREEN" },
            {
              key: "Grade",
              value: "SYNTHETIC CUTTING OIL FOR GRINDING MACHINE",
            },
            { key: "Packaging Type", value: "Bucket" },
            { key: "Usage/Application", value: "Industrial" },
          ],
          subTitle: "MULTIMATERIAL SEMI-SYNTHETIC CUTTING OIL",
          additionalInformation: [
            { key: "Delivery Time", value: "1 WEEK" },
            {
              key: "Packaging Details",
              value: "26 & 210 LTRS HDEP BUCKET & BARRAL",
            },
          ],
        },
      ],
    },
    {
      title: "EDM Oil",
      para1: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolore accusantium alias ipsa corrupti corporis tempora placeat quaerat in nesciunt quae atque, cupiditate doloribus asperiores, eius earum laudantium dolor esse vitae.`,
      para2: ``,
      subProducts: [
        {
          title: "SOLTECH EDM OIL 900",
          image:
            "https://soltechindia.co.in/images/semi-synthetic-coolant-oil.webp",
          pricePerLitre: "145",
          minimumOrderQuantity: "210",
          features: [
            { key: "Grade", value: "EDM 900" },
            { key: "Usage/Application", value: "EDM MACHINE" },
            { key: "Unit Pack Size", value: "210 LTRS" },
            { key: "Packaging Type", value: "Barrel" },
            { key: "Quantity Per Pack", value: "210 LTRS" },
            { key: "Brand", value: "SOLTECH" },
          ],
          subTitle: "EDM Machine Oil",
          additionalInformation: [],
        },
      ],
    },
    {
      title: "Gear Oil",
      para1: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolore accusantium alias ipsa corrupti corporis tempora placeat quaerat in nesciunt quae atque, cupiditate doloribus asperiores, eius earum laudantium dolor esse vitae. `,
      para2: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolore accusantium alias ipsa corrupti corporis tempora placeat quaerat in nesciunt quae atque, cupiditate doloribus asperiores, eius earum laudantium dolor esse vitae.`,
      subProducts: [
        {
          title: "GEAR OIL EP 220",
          image:
            "https://soltechindia.co.in/images/semi-synthetic-coolant-oil.webp",
          pricePerLitre: "220",
          minimumOrderQuantity: "10",
          features: [
            { key: "Unit Pack Size", value: "26 Litre" },
            { key: "Packaging Type", value: "Barrel" },
            { key: "Grade", value: "EP 220" },
            { key: "Packaging Size", value: "Bucket of 26 L" },
            { key: "Vehicle Type", value: "Heavy Vehicle, GEAR BOX" },
            { key: "I Deal In", value: "New Only" },
            { key: "Brand", value: "SOLTECH" },
            { key: "Type Of Gear Oil", value: "Compressor Oil" },
          ],
          subTitle: "GEAR OIL",
          additionalInformation: [
            { key: "Delivery Time", value: "1 WEEK" },
            {
              key: "Packaging Details",
              value: "26 LTRS / 210 LTRS HDEP BUCKET & DRUMS",
            },
          ],
        },
        {
          title: "GEAR OIL EP 320",
          image:
            "https://soltechindia.co.in/images/semi-synthetic-coolant-oil.webp",
          pricePerLitre: "240",
          minimumOrderQuantity: "10",
          features: [
            { key: "Usage/Application", value: "Gear Box" },
            { key: "Packaging Type", value: "Bucket" },
            { key: "Grade", value: "EP 320" },
            { key: "Packaging Size", value: "26 Litre" },
            { key: "Brand", value: "SOLTECH" },
          ],
          subTitle: "GEAR OIL ",
          additionalInformation: [],
        },
        {
          title: "GEAR OIL EP 90",
          image:
            "https://soltechindia.co.in/images/semi-synthetic-coolant-oil.webp",
          pricePerLitre: "180",
          minimumOrderQuantity: "26",
          features: [
            { key: "Usage/Application", value: "Gear Box" },
            { key: "Packaging Type", value: "Barrel" },
            { key: "Brand", value: "SOLTECH" },
            { key: "Packaging Size", value: "26 Litre" },
            { key: "Batch No.", value: "1030/Nov 22" },
          ],
          subTitle: "GEAR OIL",
          additionalInformation: [],
        },
        {
          title: "GEAR OIL EP 140",
          image:
            "https://soltechindia.co.in/images/semi-synthetic-coolant-oil.webp",
          pricePerLitre: "180",
          minimumOrderQuantity: "10",
          features: [
            { key: "Packaging Type", value: "Barrel" },
            { key: "Grade", value: "EP 140" },
            { key: "Brand", value: "SOLTECH" },
            { key: "Packaging Size", value: "26 Litre" },
            { key: "Vehicle Type", value: "Heavy Vehicle, GEAR BOX" },
          ],
          subTitle: "GEAR OIL",
          additionalInformation: [],
        },
      ],
    },
    {
      title: "Lubricant Oil",
      para1: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolore accusantium alias ipsa corrupti corporis tempora placeat quaerat in nesciunt quae atque, cupiditate doloribus asperiores, eius earum laudantium dolor esse vitae.`,
      para2: ``,
      subProducts: [
        {
          title: "SOLTECH SOLRUSP DW 1200",
          image:
            "https://soltechindia.co.in/images/semi-synthetic-coolant-oil.webp",
          pricePerLitre: "195",
          minimumOrderQuantity: "26",
          features: [
            { key: "Color", value: "Pale Yellow" },
            { key: "I Deal In", value: "New Only" },
            { key: "Country of Origin", value: "Made In India" },
            { key: "Usage/Application", value: "Industrial" },
            { key: "Grade", value: "Rust-preventive Oil" },
            { key: "Packaging Type", value: "Barrel" },
            { key: "Packaging Size", value: "Bucket of 26 L" },
          ],
          subTitle: "Rust-Preventive Oil",
          additionalInformation: [{ key: "Delivery Time", value: "1 WEEK" }],
        },
        {
          title: "WAYLUBE 220",
          image:
            "https://soltechindia.co.in/images/semi-synthetic-coolant-oil.webp",
          pricePerLitre: "240",
          minimumOrderQuantity: "26",
          features: [
            { key: "Usage/Application", value: "Industrial" },
            { key: "Packaging Type", value: "Barrel" },
            { key: "Grade", value: "Waylube 220" },
            { key: "Unit Pack Size", value: "26 Litres" },
          ],
          subTitle: "SLIDE WAY LUBRICATION OIL",
          additionalInformation: [],
        },
        {
          title: "SOLTECH SOLCLEAN -90",
          image:
            "https://soltechindia.co.in/images/semi-synthetic-coolant-oil.webp",
          pricePerLitre: "187",
          minimumOrderQuantity: "26",
          features: [
            { key: "Packaging Form", value: "26 LTRS / 210 LTRS" },
            {
              key: "Usage/Application",
              value: "Industrial Component Washing Chemical",
            },
            { key: "Brand", value: "SOLTECH" },
            { key: "Packaging Size", value: "	26 / 210 Ltrs" },
          ],
          subTitle: "Industrial Washing Chemical",
          additionalInformation: [],
        },
      ],
    },
    {
      title: "Semi Synthetic Coolant Oil",
      para1: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolore accusantium alias ipsa corrupti corporis tempora placeat quaerat in nesciunt quae atque, cupiditate doloribus asperiores, eius earum laudantium dolor esse vitae.`,
      para2: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolore accusantium alias ipsa corrupti corporis tempora placeat quaerat in nesciunt quae atque, cupiditate doloribus asperiores, eius earum laudantium dolor esse vitae..`,
      subProducts: [
        {
          title: "SMOOTHCUT SUPER 999 AL",
          image:
            "https://soltechindia.co.in/images/semi-synthetic-coolant-oil.webp",
          pricePerLitre: "280",
          minimumOrderQuantity: "10",
          features: [
            { key: "Packaging Size", value: "26 Litre" },
            { key: "Physical State", value: "Liquid" },
            { key: "Usage/Application", value: "Industrial" },
            { key: "Packaging Type", value: "Bucket" },
            { key: "Brand", value: "SOLTECH" },
            { key: "Model Name/Number", value: "SMOOTHCUT SUPER 999 AL" },
          ],
          subTitle: "Semi-Synthetic Cutting Oil ",
          additionalInformation: [],
        },
      ],
    },
  ];
  const [currentIndex, setCurrentIndex] = useState(null);
  return (
    <div className="mt-0 pb-5 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
        <div className="fs-medium-css fw-bold mt-4 color-theme-black col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 display-flex">
          Select Type Of Product:
        </div>

        <div className="mt-4 color-theme-orange col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 px-0 display-flex">
          <Select
            className="w-100"
            options={[
              {
                id: 0,
                label: "Cutting Oil",
              },
              {
                id: 1,
                label: "EDM Oil",
              },
              {
                id: 2,
                label: "Gear Oil",
              },
              {
                id: 3,
                label: "Lubricant Oil",
              },
              {
                id: 4,
                label: "Semi-Synthetic Coolant Oil",
              },
            ]}
            onChange={(selectedProductType) =>
              setCurrentIndex(selectedProductType.id)
            }
          />
        </div>
      </div>

      {currentIndex !== null && (
        <>
          <div className="mt-4 color-theme-orange fs-larger-css fw-bold col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
            {productsData[currentIndex].title}
          </div>

          {productsData[currentIndex].para1 !== `` && (
            <div className="mt-4 color-theme-black  fw-normal col-11 col-sm-11 col-md-9 col-lg-8 col-xl-8 row px-0 display-flex text-center">
              {productsData[currentIndex].para1}
            </div>
          )}

          {productsData[currentIndex].para2 !== `` && (
            <div className="mt-4 color-theme-black  fw-normal col-11 col-sm-11 col-md-9 col-lg-8 col-xl-8 row px-0 display-flex text-center">
              {productsData[currentIndex].para2}
            </div>
          )}

          <div className="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 row px-0 display-flex-align-items-top">
            {productsData[currentIndex].subProducts.map((ele, index) => (
              <div
                key={index}
                className="mt-4 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-4 display-flex-align-items-top"
              >
                <div className="card-shadow pb-4 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-align-items-top">
                  <div className="border-orange-thin col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
                    <img src={ele.image} alt="" className="product-image p-4" />
                  </div>
                  {/* image */}

                  <div className="product-content-height col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-4 display-flex-align-items-top ">

                    <div className="mt-4 fs-medium-css  color-theme-black col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                      {ele.title}
                    </div>
                    <div className="mt-2 fw-normal  color-theme-black col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                      Price :{" "}
                      <span className="color-theme-orange w-auto px-1 fw-bold">
                        {ele.pricePerLitre}
                      </span>{" "}
                      /Litre
                    </div>
                    <div className="mt-2 fw-normal  color-theme-black col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                      Minimum Order Quantity :{" "}
                      <span className="color-theme-orange w-auto px-1 fw-bold">
                        {ele.minimumOrderQuantity}
                      </span>{" "}
                      Litres
                    </div>
                    {ele.features.length !== 0 && (
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-align-items-top">
                        <div className="mt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start color-theme-orange">
                          Specifications :
                        </div>
                        {ele.features.map((ele, index) => (
                          <div
                            key={index}
                            className="mt-2 fs-slight-small-css fw-normal col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start"
                          >
                            <i class="fa-solid fa-angles-right color-theme-orange w-auto pe-1"></i>
                            {ele.key} :{" "}
                            <span className="fw-bold w-auto px-1">
                              {ele.value}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                    <div className="mt-4  color-theme-orange col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                      {ele.subTitle.toUpperCase()}
                    </div>
                    {ele.additionalInformation.length !== 0 && (
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-align-items-top">
                        <div className="mt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start color-theme-orange">
                          Additional Information :
                        </div>
                        {ele.additionalInformation.map((ele, index) => (
                          <div
                            key={index}
                            className="fs-slight-small-css fw-normal mt-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start"
                          >
                            <i class="fa-solid fa-angles-right color-theme-orange w-auto pe-1"></i>
                            {ele.key} :{" "}
                            <span className="fw-bold w-auto px-1">
                              {ele.value}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {/* div end  */}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
