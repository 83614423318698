import React from "react";
import {motion} from "framer-motion";

export default function AboutUsContent() {
  return (
    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
      <div className="bg-theme-grey pb-5 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-align-items-top">
          <motion.div
          // initial={{x:-400, opacity:0}}
          // whileInView={{x:0, opacity:1}}
          // transition={{duration:1.5, damping:200}}
          className="position-relative-universal mt-5 col-11 col-sm-11 col-md-11 col-lg-6 col-xl-6 row px-5 display-flex">
            <img
              src={"https://soltechindia.co.in/images/about-us-main.jpg"}
              className="about-us-main-image px-0"
              alt="No image"
            />
            <motion.img
            initial={{y:0}}
            animate={{y:[0,70,0]}}
            transition={{duration:5.5, damping:0, repeat:Infinity}}

              src={"https://soltechindia.co.in/images/about-us-top.jpg"}
              className="about-us-top-image px-0"
              alt="No image"
            />
          </motion.div>

          <motion.div
          // initial={{x:400, opacity:0}}
          // whileInView={{x:0, opacity:1}}
          // transition={{duration:1.5, damping:200}} 
          className="mt-5 col-11 col-sm-11 col-md-11 col-lg-6 col-xl-6 row px-5 display-flex">
            <div className="color-theme-orange w-100 px-0 m-0 display-flex-start fs-medium-css fw-bold">
              About Us
            </div>

            <div className="w-100 px-0 mt-2 display-flex-start fs-large-css fw-bold">
              NEW GENERATION METALWORKING FLUIDS AND SPECIALITIES
            </div>

            <div className="w-100 px-0 mt-3 display-flex-start fs-slight-small-css fw-normal">
              We are committed to provide safe
industrial solutions to many factories.
Ultimate Solution is
one stop solution for all your industrial Lubricant, MRO, safety
& consumable. Delivery all over India with best market
competative rate. No burden of carrying inventory, just shop
when you required.
Established in the year 2016, ultimate solution Pune is
a top player in the category lubricant oil & industrial
variety dealers in the Pune.
            </div>
          </motion.div>
        </div>
      </div>

        <div className="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 row px-0 display-flex-align-items-top">
          <motion.div
          
          initial={{x:800, opacity:0}}
          animate={{x:0, opacity:1}}
          transition={{duration:1.5, damping:200, delay:0}} 
          className="py-5 my-5 col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 row px-5 display-flex">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                <i className=" px-0 fs-largest-css color-theme-orange fa-solid fa-eye-low-vision"></i>
              </div>
              <div className="mt-3 fs-medium-css fw-bold color-theme-black col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mx-0 row px-0 display-flex-start">
                Vision
              </div>
              <div className="mt-2 fw-normal color-theme-grey col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start text-align-justify">
                To achive international standards in all aspects of Lubricants and
                Greases with focus on customer delight through value of its
                products and services and responsible environmental performance.
                To maximize creation of wealth,value and satisfaction for all
                stake holders
              </div>
            </div>
          </motion.div>
          <motion.div
          initial={{x:600, opacity:0}}
          animate={{x:0, opacity:1}}
          transition={{duration:1.5, damping:200, delay:1.5}}  className="border-left-orange py-5 my-5 col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 row px-5 display-flex">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                <i className=" px-0 fs-largest-css color-theme-orange fa-solid fa-bullseye"></i>
              </div>
              <div className="mt-3 fs-medium-css fw-bold color-theme-black col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mx-0 row px-0 display-flex-start">
                Mission
              </div>
              <div className="mt-2 fw-normal color-theme-grey col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start text-align-justify">
                To manufacture and market high quality products so satisfy
                customer needs globally and constantly thrive to conduct business
                in a transparent, trustworthy and safe manner through teamwork,
                integrity and innovation.
              </div>
            </div>
          </motion.div>
          <motion.div
          initial={{x:400, opacity:0}}
          animate={{x:0, opacity:1}}
          transition={{duration:1.5, damping:200, delay:3}}  className="border-left-orange py-5 my-5 col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 row px-5 display-flex">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                <i className=" px-0 fs-largest-css color-theme-orange fa-solid fa-medal"></i>
              </div>
              <div className="mt-3 fs-medium-css fw-bold color-theme-black col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mx-0 row px-0 display-flex-start">
                Value
              </div>
              <div className="mt-2 fw-normal color-theme-grey col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start text-align-justify">
              We integrate honesty rates best. Integrity and business ethics in to all aspects of our business functioning, Customer Orientation, Continous Improvement, Preffered Business Partner, and High Level Of Business Ethics.
              </div>
            </div>
          </motion.div>
        </div>
    </div>
  );
}
