import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "./Header";
import { motion } from "framer-motion";
import Super30 from "./PDS SOLCUT SUPER 30.pdf";
import Super50 from "./PDS SOLCUT SUPER 50.pdf";
import Super100 from "./PDS SOLCUT SUPER 100.pdf";
import Super903 from "./PDS SMOOTHCUT SUPER 903.pdf";
import Super907 from "./PDS SMOOTHCUT SUPER 907.pdf";
import Super7000 from "./PDS SMOOTHCUT SUPER 7000 BS.pdf";
import Neatcut1014 from "./PDS SOL NEATCUT 1014.pdf";
import Edm900 from "./PDS EDM OIL 900.pdf";
import Solclean90 from "./PDS SOLCLEAN 90.docx";
import Sol68 from "./PDS SOL WAYLUBE 68.pdf";
import Sol1200 from "./PDS SOLRUSP DW 1200.pdf";
import SolWrl1200 from "./PDS SOL WRL 1200.pdf";
import Super909 from "./PDS SMOOTHCUT SUPER 909.pdf";
import Sol3500 from "./PDS SOL MISTCUT ES 3500.pdf";
import Sol1007 from "./PDS SOL HONNING OIL 1007.pdf";
import Sol1045 from "./PDS SOL NEAT CUT 1045.pdf";
import Sol1022 from "./PDS SOL NEAT CUT 1022.pdf";
import AwSeries from "./TDS HYDRASOL AW SERIES.pdf";
import HlpSeries from "./TDS HYDRASOL HLP SERIES.pdf";
import EpSeries from "./PDS SOLTECH GEAR OIL EP SERIES.pdf";
import Sol1141 from "./PDS SOL RUSTREMOVER 1141.pdf";
import SolTray from "./PDS SOL TRAY WASH.pdf";
import ChainCare3500 from "./PDS SOL CHAIN CARE 3500.pdf";
import Sol800 from "./PDS SOL TAPPING OIL 800.pdf";
import Sol2000 from "./PDS SOL TAPPING OIL 2000.pdf";
import SolRust2000 from './PDS SOL RUST PREVENTIVE OIL 2000.pdf';

export default function ProductDetails() {
  const products = [
    {
      urlRoute: "metal-working-fluids",
      title:"Metal Working Fluids",
      product1Title: "Metal Working Fluids",
      product1Image: "https://soltechindia.co.in/images/metal-working-fluid.jpg",
      product1Description: `SOLTECH  offers a wide range of metalworking fluids including synthetic, semi synthetic and mineral base metal working fluids designed to enhance machining operations across diverse industrial applications. These fluids are formulated to dissolve easily in water, providing excellent lubrication, cooling, and corrosion protection for metal workpieces and cutting tools during machining, milling, grinding, and other metalworking processes.
SOLTECH’s water-soluble MWFs are carefully crafted to meet specific performance requirements, including improved surface finish, extended tool life, and enhanced productivity. Their advanced formulations help minimize friction, reduce heat buildup, and flush away metal chips and debris, contributing to higher machining efficiency and precision.`,
productDataSheets : [
  {name:"PDS SOLCUT SUPER 30", docFile: Super30},
  {name:"PDS SOLCUT SUPER 50", docFile: Super50},
  {name:"PDS SOLCUT SUPER 100", docFile: Super100},
  {name:"PDS SMOOTHCUT SUPER 903", docFile: Super903},
  {name:"PDS SMOOTHCUT SUPER 907", docFile: Super907},
  {name: "PDS SMOOTHCUT SUPER 909" , docFile : Super909},
  {name : "PDS SMOOTHCUT SUPER 7000 BS" , docFile : Super7000}
]
    },
    {
      urlRoute: "neat-cutting-oil",
      title:"Neat Cutting Oil",
      product1Title: "Neat Cutting Oil",
      product1Image: "https://soltechindia.co.in/images/metal-forming-oil.jpg",
      product1Description:`SOLTECH  offers a full range of neat cutting oils and metal forming oils designed to meet the rigorous demands of machining and metal forming processes.
Both types of oils meet strict quality standards, delivering consistent perfor`,
productDataSheets : [
  {name:"PDS SOL NEATCUT 1014", docFile: Neatcut1014},
  {name:"PDS SOL MISTCUT ES 3500", docFile: Sol3500},
  {name:"PDS SOL HONNING OIL 1007", docFile: Sol1007},
  {name:"PDS SOL NEAT CUT 1022", docFile: Sol1022},
  {name:"PDS SOL NEAT CUT 1045", docFile: Sol1045}

]
    },
    {
      urlRoute: "hydraulic-oil",
      title:"Hydraulic Oil",
      product1Title: "Hydraulic Oil",
      product1Image:
        "https://soltechindia.co.in/images/hydraulic-oil.jpg",
      product1Description: `
      Hydraulic oil from SOLTECH  is a premium-grade lubricant specially formulated for hydraulic systems. With superior anti-wear properties and excellent thermal stability, it ensures smooth operation and prolongs the lifespan of hydraulic components.
Its high viscosity index provides consistent performance across a wide range of temperatures, while additives prevent rust, corrosion, and foam formation, maintaining system integrity.
SOLTECH’s hydraulic oil guarantees reliable performance, efficiency, and protection for your hydraulic machinery, making it the top choice for industrial applications.`,
productDataSheets : [
  // {name:"PDS SOL WAYLUBE 68", docFile: Sol68},
  // {name:"PDS SOLCLEAN 90", docFile: Solclean90},
  {name:"TDS HYDRASOL AW SERIES", docFile: AwSeries},
  {name:"TDS HYDRASOL HLP SERIES", docFile: HlpSeries},
]
    },
    {
      urlRoute: "gear-oil",
      title:"Gear Oil",
      product1Title: "Gear Oil",
      product1Image:
        "https://soltechindia.co.in/images/hydraulic-and-gear-oil.webp",
      product1Description: `Gear oil from SOLTECH  is a high-quality lubricant designed specifically for gear systems. Its advanced formula offers exceptional protection against wear, friction, and corrosion, ensuring smooth gear operation and extending equipment life. With optimal viscosity and thermal stability, it maintains consistent performance in various operating conditions.
Special additives in SOLTECH’s gear oil prevent oxidation and foam formation, enhancing gear efficiency and reliability. Trusted by  worldwide, SOLTECH’s gear oil delivers superior lubrication and durability, making it the ideal choice for your gear systems.`,productDataSheets : [
  {name:"PDS SOLTECH GEAR OIL EP SERIES", docFile: EpSeries},
]
    },
    {
      urlRoute: "waylube-oil",
      title:"WayLube Oil",
      product1Title: "WayLube Oil",
      product1Image:
        "https://soltechindia.co.in/images/drawing-and-stamping-lubricants.jpg",
      product1Description: `WayLube Oils are designed to facilitate the smooth movement of metal sheets or blanks through dies during drawing operations. These lubricants reduce friction and wear, improving surface finish and dimensional accuracy while preventing defects like tearing and wrinkling.
Stamping lubricants are formulated to enhance the performance of stamping processes, where metal parts are formed by pressing or stamping operations. These lubricants provide effective lubrication between the tool and the workpiece, reducing friction and preventing galling or sticking. They also help to prolong the life of stamping dies and maintain consistent part quality.`,productDataSheets : [
  {name:"PDS SOL WAYLUBE 68", docFile: Sol68}
]
    },
    {
      urlRoute: "edm-and-carrier-oil",
      title:"EDM & Carrier Oil",
      product1Title: "EDM Machine Oil",
      product1Image: "https://soltechindia.co.in/images/edm-oil-product.jpg",
      product1Description: `SOLTECH  offers specialized EDM (Electrical Discharge Machining) machine oil tailored to meet the exacting requirements of EDM operations.
EDM machine oil is designed to provide effective dielectric properties, ensuring efficient electrical discharge and optimal machining performance. It also acts as a coolant, dissipating heat generated during the machining process to prevent thermal damage to workpieces and electrodes.
SOLTECH’s EDM machine oil is formulated to resist thermal breakdown and oxidation, maintaining stable performance over extended machining periods. It also helps to minimize electrode wear and improve surface finish quality on machined parts.`,
      product2Title: "Carrier oil for MPI Testing",
      product2Image: "https://soltechindia.co.in/images/edm-and-carrier-oil.webp",
      product2Description: `SOLTECH  offers a high-quality carrier oil specifically designed for MPI (Magnetic Particle Inspection) testing applications. This carrier oil is formulated to provide excellent wetting properties, ensuring optimal penetration of magnetic particles into surface defects for accurate flaw detection. With superior lubricating qualities, it facilitates smooth movement of the particles over the surface being inspected, enhancing the sensitivity and reliability of the testing process.
SOLTECH’s MPI testing carrier oil is meticulously manufactured to meet stringent industry standards, making it an essential component for non-destructive testing procedures in various industrial applications.`,
productDataSheets : [
  {name:"PDS EDM OIL 900", docFile: Edm900}
]
    },
    {
      urlRoute: "rust-preventive-solutions",
      title:"Rust Preventive Solutions",
      product1Title: "Rust Preventive Solutions",
      product1Image:
        "https://soltechindia.co.in/images/rust-preventive-solutions.jpg",
      product1Description: `SOLTECH manufactures rust preventive oils and solutions to protect metals from rust and corrosion.These solutions include water-based, solvent -based, and oil-based formulas for short -term or long-term protection. Products include corrosion inhibitors for in -process protection as well as for the protection of parts in storage and shipping.
Our rust preventive oils, that contain those corrosion inhibitors , from a barrier film on the substrate surface. The inhibitor molecules adsorb on the surface, forming a film protecting the part from the attack of oxygen, water, and other chemically active substances.`,
productDataSheets:[
  {name:"PDS SOLRUSP DW 1200", docFile: Sol1200},
  {name:"PDS SOL RUST PREVENTIVE OIL 2000", docFile: SolRust2000}
]
    },
    {
      urlRoute: "rust-remover-solutions",
      title:"Rust Remover Solutions",
      product1Title: "Rust Remover Solutions",
      product1Image:
        "https://soltechindia.co.in/images/rust-removal-solutions.jpg",
        product1Description: `SOLTECH  Pvt Ltd offers a range of rust remover chemicals designed for industrial applications to effectively remove rust from components. These rust removers are formulated to ensure thorough and efficient rust elimination while being safe for use on various metals and surfaces.`,
        productDataSheets : [
          {name:"PDS SOL RUSTREMOVER 1141", docFile: Sol1141},
        
        ]
    },
    {
      urlRoute: "cleaners-and-degreasers",
      title:"Cleaners & Degreasers",
      product1Title: "Cleaners & Degreasers",
      product1Image:
        "https://soltechindia.co.in/images/cleaners-and-degreasers.jpeg",
      product1Description: `SOLTECH  Pvt. Ltd. specializes in high-performance cleaners and degreasers for industrial use. Our products effectively remove grease, oil, dirt, and contaminants from metal surfaces, ensuring optimal machinery condition and performance.
Designed to penetrate and dissolve grime, our cleaners and degreasers leave surfaces spotless and residue-free, reducing downtime and enhancing operational efficiency. They are user-friendly, non-toxic, and environmentally friendly, prioritizing safety and sustainability.
Choose SOLTECH  Pvt. Ltd. for reliable, effective, and eco-conscious solutions to keep your industrial operations running smoothly and efficiently.
`,
productDataSheets : [
  {name:"PDS SOLCLEAN 90", docFile: Solclean90},
  {name:"PDS SOL TRAY WASH", docFile: SolTray}
]
    },
    {
      urlRoute: "quenching-oil",
      title:"Quenching Oil",
      product1Title: "Quenching Oil",
      product1Image: "https://soltechindia.co.in/images/quenching-oil.webp",
      product1Description: `SOLTECH  offers a premium range of quenching oils engineered to meet the rigorous demands of heat treatment processes. Our quenching oils are meticulously formulated to deliver rapid and uniform cooling, ensuring optimal hardness and mechanical properties in heat-treated components. With superior thermal stability and oxidation resistance, SOLTECH’s quenching oils minimize the risk of distortion, cracking, and quenching-related defects, resulting in superior part quality and consistency. Whether for batch or continuous quenching applications, our oils provide reliable performance, helping manufacturers achieve precise metallurgical outcomes while extending the service life of quench baths and equipment. a wide range of metalworking fluids including synthetic, semi synthetic and mineral base metal working fluids designed to enhance machining operations across diverse industrial applications. These fluids are formulated to dissolve easily in water, providing excellent lubrication, cooling, and corrosion protection for metal workpieces and cutting tools during machining, milling, grinding, and other metalworking processes.`,
    },
    {
      urlRoute: "speciality-lubricants",
      title:"Speciality Lubricants",
      product1Title: "Speciality Lubricants",
      product1Image: "https://soltechindia.co.in/images/speciality-lubricants.jpg",
      product1Description: `SOLTECH  Pvt Ltd offers a comprehensive range of specialty lubricants designed to meet the specific needs of various industrial applications. These lubricants are formulated with advanced technology to provide superior performance, protection, and reliability under demanding conditions.
The SOLTECH specialty lubricants range includes products tailored for high temperatures, heavy loads, extreme environments, and specific applications, ensuring optimal lubrication and maintenance of industrial equipment.`,
productDataSheets : [
  // {name:"PDS SOLCLEAN 90", docFile: Solclean90},
  // {name:"PDS SOL TRAY WASH", docFile: SolTray},
  {name:"PDS SOL CHAIN CARE 3500", docFile: ChainCare3500},
  {name:"PDS SOL TAPPING OIL 800", docFile: Sol800},
  {name:"PDS SOL TAPPING OIL 2000", docFile: Sol2000},
  {name:"PDS SOL WRL 1200", docFile: SolWrl1200},
]
    },
    {
      urlRoute: "greases",
      title:"Greases",
      product1Title: "Greases",
      product1Image: "https://soltechindia.co.in/images/greases.jpg",
      product1Description: `Grease is a solid or semisolid lubricant formed as a dispersion of thickening agents in a liquid lubricant. Grease generally consists of a soap emulsified with mineral or vegetable oil.
A common feature of greases is that they possess high initial viscosities, which upon the application of shear, drop to give the effect of an oil-lubricated bearing of approximately the same viscosity as the base oil used in the grease. This change in viscosity is called shear thinning.
Greases are applied to mechanisms that can be lubricated only infrequently and where a lubricating oil would not stay in position. They also act as sealants to prevent the ingress of water and incompressible materials. Grease-lubricated bearings have greater frictional characteristics because of their high viscosities.
`,
    },
  ];
  const { productCategory } = useParams();
  const [currentProduct, setCurrentProduct] = useState({});
  useEffect(() => {
    setCurrentProduct(
      products.filter((ele) => ele.urlRoute === productCategory)[0]
    );

    window.scroll(0, 0);
  }, [productCategory]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1.5, damping: 200 }}
      className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex"
    >
      <Header title={currentProduct.title} />

      <motion.div
        initial={{ opacity: 0, y: 200 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.5, damping: 200 }}
        className="py-5 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex"
      >
        <div className="m-0 col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 row px-0 display-flex py-0">
          <div className=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-align-items-top mx-0 px-0">
            
            <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 row px-0 display-flex">
                <div className="col-12 co-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-align-items-top ">
                    <div
                      className={`${
                        window.innerWidth > 995 && "product-image-container"
                      } mx-0 mt-3  col-12 col-sm-12 col-md-9 col-lg-5 col-xl-5 row px-0 display-flex`}
                    >
                      <img
                        src={currentProduct.product1Image}
                        className="product-image-css px-0 w-100 mx-0"
                        alt=""
                      />
                    </div>
                    <div className="mt-3 col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 row mx-0 pe-0 ps-0 py-0 display-flex-align-items-top">
                      <div className="mt-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start color-theme-black fs-large-css fw-bold fw-normal">
                      {currentProduct.product1Title}
                      </div>
                      <div className="mt-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 fw-normal text-align-justify display-flex">
                        {currentProduct.product1Description}
                      </div>
                    </div>
                </div>
                
                <div className="mt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-align-items-top">
                    {currentProduct.product2Title && <>
                        <div
                      className={`${
                        window.innerWidth > 995 && "product-image-container"
                      } mx-0 mt-3 col-12 col-sm-12 col-md-9 col-lg-5 col-xl-5 row px-0 display-flex`}
                    >
                      <img
                        src={currentProduct.product2Image}
                        className="product-image-css px-0 w-100 mx-0"
                        alt=""
                      />
                    </div>
                    <div className="mt-3 col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 row mx-0 pe-0 ps-0 py-0 display-flex-align-items-top">
                      <div className="mt-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start color-theme-black fs-large-css fw-bold fw-normal">
                      {currentProduct.product2Title}
                      </div>
                      <div className="mt-4 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 fw-normal text-align-justify display-flex">
                        {currentProduct.product2Description}
                      </div>
                    </div>
                    </>}
                </div>
            </div>

            <div
              className={`${
                window.innerWidth > 995 && "position-right-sticky"
              } hide-scrollbar border-left-grey col-12 col-sm-12 mx-0 col-md-12 col-lg-3 col-xl-3 row ps-3  pe-0 display-flex-align-items-top`}
            >
              {currentProduct.productDataSheets && <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start  color-theme-orange fw-bold fs-medium-css">
                PRODUCTS
              </div>}

              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-align-items-top">
                {currentProduct.productDataSheets &&
                currentProduct.productDataSheets.map((ele,index)=>
                <div key={index} className="mt-0  col-12 col-sm-12 col-md-6 col-lg-12 col-xl-12 row px-0 display-flex">
                  <div className="mt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start fw-bold">
                    <i className="color-theme-orange fs-medium-css w-auto ms-0 me-2 fa-solid fa-angles-right px-0 "></i>
                    {ele.name}
                  </div>
                  <div className="mt-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                    <a href={ele.docFile} download={ele.name} className="text-decoration-none btn-product fs-small-css w-auto">
                      Download PRODUCT SHEET
                    </a>
                  </div>
                </div>)}
              </div>

            
            </div>

          </div>
        </div>
      </motion.div>
    </motion.div>
  );
}
