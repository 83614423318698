import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function AllProductsPage() {
    const navigate = useNavigate();
    const products = [
        {label : "Metal Working Fluid", route : "metal-working-fluids"},
        {label : "Neat Cutting Oil", route : "neat-cutting-oil"},
        {label : "Hydraulic Oil", route : "hydraulic-oil"},
        {label : "Gear Oil", route : "gear-oil"},
        {label : "WayLube Oil", route : "waylube-oil"},
        {label : "EDM & Carrier Oil", route : "edm-and-carrier-oil"},
        {label : "Rust Preventive Solutions", route : "rust-preventive-solutions"},
        {label : "Rust Remover Solutions", route : "rust-remover-solutions"},
        {label : "Cleaners & degreasers", route : "cleaners-and-degreasers"},
        {label : "Quenching Oil", route : "quenching-oil"},
        {label : "Speciality Lubricants", route : "speciality-lubricants"},
        {label : "Greases", route : "greases"}
    ];
  return (
    <div className='mb-4 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 row px-0 display-flex'>

<div className='mt-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 row px-0 display-flex text-center fw-bold fs-large-css color-theme-orange'>
    ALL PRODUCTS
    </div>
        <div className='mt-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 row px-0 display-flex'>
            {products.map((ele,index)=>
                <div key={index} className='mt-0 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4 row px-5 display-flex'>
                    <button
                    onClick={()=>navigate(`/productDetails/${ele.route}`)}
                    className="btn-product fw-bold w-100 py-2">
                        {ele.label}
                    </button>
                </div>
            )}
        </div>
    </div>
  )
}
