import React, { useEffect, useState } from "react";
import {motion} from "framer-motion";
import { Carousel } from "primereact/carousel";

export default function EnquireNow() {
  const testimonialsData = [
    {
      image: "https://www.incubeindia.com/incubelandmarks/images/male.webp",
      name: "Mr.Shrikant Misal",
      post: `Assembly Quality 
Jagadamba Auto components Ltd.
`,
      comment: `We are using SOLCLEAN 90 washing chemical which improving our product cleaning quality. By using this product, we achieved customer Millipore value and customer satisfaction`,
    },
    {
      image: "https://profile.justdial.com/profileImg?i=GksaU2%2FzmpI%2B8IjtPFnT%2FLrUhf9g1Atz0Gcamn3Uwm8%3D",
      name: "Mr. Sachin Nakate",
      post: `Director
Aai Tech Industries
`,
      comment: `We are using HYDRASOL AW 68 (Hydraulic Oil) having excellent lubricity and quality. Value for money product`,
    },
    {
      image: "https://profile.justdial.com/profileImg?i=zQVqlqdJfExJYOFRSdcpNwFzaR0kT5%2BkgFETnvAHWjo%3D",
      name: "Mr. Ram Kale",
      post: `Director
Pragati Press tools India Pvt. Ltd
`,
      comment: `We are using SMOOTHCUT SUPER 907 – Semi-synthetic cutting oil which improves tool life, sum life and product quality`
    },
    {
      image: "https://profile.justdial.com/profileImg?i=cS6HahEqaRDBC%2Ff4Pqgr7fK%2Ff%2FhyfP%2Bp%2B5toR6qfRpc%3D",
      name: "Mr. Shantanu",
      post: `Production Incharge,
Manisha Engineering Works
`,
      comment: `We are using SMOOTHCUT SUPER 907 – Semi-synthetic cutting oil excellent quality cutting oil for casting also. We are very happy for product quality and services`
    },
    {
      image: "https://profile.justdial.com/profileImg?i=fJLfe9GwuWRvA%2F1YDlplzH6S%2BM7nIMAksrPt4xTX%2F3c%3D",
      name: "Mr. Rakesh Narawade",
      post: `Partner,
Rameshwar Enterprises
`,
      comment: `We have been using metal working fluid SOLCUT SUPER 50 – Water soluble cutting oil best quality cutting oil for multi material. It offers very good lubricity`
    },
    {
      image: "https://profile.justdial.com/profileImg?i=94lXpjXdeseMTc49kFrCYqQl36Cb1EGuy7JHsDiUV9A%3D",
      name: "Mr. Tushar Chivate",
      post: `Partner,
CT strength India LLP
`,
      comment: `We have been using metal working fluid SOLCUT SUPER 50 – Water soluble cutting oil as a result of which we have enhanced tool life by 20%. The product performing exceedingly good and no bad odor and other issues`
    },
    {
      image: "https://profile.justdial.com/profileImg?i=ygHPfVnjN8z8Q6HjnXQpWv3ViiW%2BvG9srgld3J0Airw%3D",
      name: "Mr. Vipul Satasiya",
      post: `Partner,
Bhavika Biochem
`,
      comment: `We have been using cutting oil which is very milky white, no bad odor and other issues product is very best in quality and service is very best.`
    },
    {
      image: "https://profile.justdial.com/profileImg?i=7DkVmT%2BpMZABfzFADu3NIuny19gmreBVbHmAVuPThjs%3D",
      name: "Mr. Abhishek Deshmukh",
      post: `Purchase Dept.
Dynamic crane engineers pvt. ltd.
`,
      comment: `Excellent gear oils, Rust Preventive oil and hydraulic oil from Soltech. Our machinery runs smoothly and efficiently. Highly recommended!`
    },
    
    {
      image: "https://profile.justdial.com/profileImg?i=2ui%2BAD9MI%2B%2BwyqjO8x6M76UCPoRbEKwXucNfQ5UgFbI%3D",
      name: "Mr. Vikas Bhuruk",
      post: `Production Manager.
Om Steel Industries
`,
      comment: `High-quality lubricants with excellent customer service. Soltech cutting oil and hydraulic oil, cleaners and degreasers are top-notch, making maintenance a breeze. Reliable and effective products! Very satisfied!`
    }
    
    

  ];

  const responsiveOptions = [
    {
      breakpoint: "2000px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "1000px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "650px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  const [formData , setFormData] = useState({});
  const [buffer, setBuffer] = useState(false);

  const [currentIndex, setCurrentIndex] = useState(0);

  function validateInputPattern(event, regex) {
    if (event.target.value !== "") {
      document
            .getElementsByName(event.target.name)[0]
            .classList.remove("compulsory");
      for (var i = 0; i < regex.length; i++) {
        if (regex[i].test(event.target.value) === true) {
          document
            .getElementsByName(event.target.name)[0]
            .classList.remove("wrong-input");
          setFormData({
            ...formData,
            [event.target.name]: event.target.value,
          });
          break;
        } else if (regex[i].test(event.target.value) === false) {
          document
            .getElementsByName(event.target.name)[0]
            .classList.add("wrong-input");
        }
      }
    }
    else
    {
      document
            .getElementsByName(event.target.name)[0]
            .classList.add("compulsory");
    }
  }

  async function handleSubmit()
  {
    try {
      setBuffer(true);
      const input_fields = document.getElementsByClassName("input-field");
      let wrong_field = "";
      let empty_field = "";
      for(var i=0;i<input_fields.length;i++)
      {
        for(var j=0;j<input_fields[i].classList.length;j++)
        {
          if(input_fields[i].classList[j]==="wrong-input")
          {
            wrong_field = input_fields[i].name ;
            break;
          }
          else if(input_fields[i].classList[j]==="compulsory")
          {
            empty_field = input_fields[i].name;
            break;
          }
          else
          {
            wrong_field = "" ;
            empty_field = "" ;
          }
        }

        if(wrong_field!=="")
        {
          alert(`Invalid Format of ${wrong_field.toUpperCase()}!`);
          document.getElementsByName(wrong_field)[0].focus();
          setBuffer(false);
          break;
        }
        else if(empty_field!=="")
        {
          alert(`${empty_field.toUpperCase()} is mandatory !`);
          document.getElementsByName(empty_field)[0].focus();
          setBuffer(false);
          break;
        }
      }

      if(wrong_field==="" && empty_field==="")
      {
        setBuffer(true);
        await fetch("https://soltechindia.co.in/sendEmail.php" ,{
          method : "post",
          body : JSON.stringify(formData)
        })
        .then(async(res)=>{
          res = await res.json();
          if(res.success)
          {
            alert(res.success);
          }
          else
          {
            alert(res.error);
          }
          setBuffer(false);
          window.location.reload();
        })
        .catch(err=>{
          console.log(err);
          alert("Something went wrong, please check your internet connectivity");
          setBuffer(false);
        })
      }

      

    } catch (error) {
      alert("Error while communicating with server!");
      setBuffer(false);
    }
  }

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonialsData.length);
        }, 5000); 

        return () => clearInterval(interval);
    }, []);

    const productTemplate = (ele) => {
      return (
        <div
                className="m-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-4 px-0 row display-flex "
              >

                  <div className="col-12  col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-align-items-top">
                    <div className="col-12  col-sm-12 col-md-12 col-lg-3 col-xl-3 row px-0 display-flex">
                    <img src="https://www.incubeindia.com/incubelandmarks/images/male.webp" alt="" className="rounded-circle testimonial-image px-0" />
                    </div>

                    <div className="col-12  col-sm-12 col-md-12 col-lg-9 col-xl-9 row px-3 display-flex">
                      <div className="col-12  col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start fs-medium-css fw-bold">
                        {ele.name}
                      </div>

                      <div className="mt-2 col-12  col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start fs-small-css">
                        {ele.post.toUpperCase()}
                      </div>

                      <div className="mt-3 col-12  col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start fs-slight-small-css">
                        {ele.comment}
                      </div>
                    </div>
                  </div>
              </div>
      );
    };
  return (
    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-align-items-top">
        <motion.div 
        // initial={{opacity:0 , x:-400}}
        // whileInView={{opacity:1 , x:0}}
        // transition={{duration:1.5 , damping:200}}

        className="p-5 bg-theme-orange col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 row display-flex">
          <div className="text-white fw-bold fs-large-css col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-3 m-0 row display-flex-start">
            Enquire Now
          </div>

          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-3 display-flex">
            <div className="mt-4 fs-light-small-css fw-normal text-white col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
              Name :
            </div>

            <div className="mt-2 fs-light-small-css fw-normal text-white col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
              <input
                className="input-field compulsory w-100"
                type="text"
                placeholder="Your Name"
                name="name"
                onChange={(event)=>validateInputPattern(event , [/^[a-zA-Z\s]+$/])}
              />
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 row px-3 display-flex">
              <div className="mt-4 fs-light-small-css fw-normal text-white col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                Email :
              </div>

              <div className="mt-2 fs-light-small-css fw-normal text-white col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
                <input
                  className="input-field compulsory w-100"
                  type="text"
                  placeholder="Your Email"
                name="email"
                onChange={(event)=>validateInputPattern(event , [
                  /^[a-zA-Z0-9!#$%&'*+/=?^_.`{|}~-]+@[a-z]+(\.[a-z]+)+$/,
                  /^[a-zA-Z0-9!#$%&'*+/=?^_.`{|}~-]+@[a-z]+(\.[a-z]+)+\.[a-z]+$/,
                  /^[a-zA-Z0-9!#$%&'*+/=?^_.`{|}~-]+@[a-z]+(\.[a-z]+)+(\.[a-z]+)+\.[a-z]+$/,
                  /^[a-zA-Z0-9!#$%&'*+/=?^_.`{|}~-]+@[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.[a-z]+$/,
                ])}
                />
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 row px-3 display-flex">
              <div className="mt-4 fs-light-small-css fw-normal text-white col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                Phone :
              </div>

              <div className="mt-2 fs-light-small-css fw-normal text-white col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
                <input
                  className="input-field compulsory w-100"
                  type="text"
                  placeholder="Your Phone"
                maxLength={10}
                name="phone"
                onChange={(event)=>validateInputPattern(event , [/^\d{10}$/])}
                />
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-3 display-flex">
            <div className="mt-4 fs-light-small-css fw-normal text-white col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
              Message :
            </div>

            <div className="mt-2 fs-light-small-css fw-normal text-white col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
              <textarea
                className="input-field compulsory w-100"
                type="text"
                maxLength={300}
                placeholder="Your Message"
                name="message"
                onChange={(event)=>validateInputPattern(event , [/^[A-Za-z0-9\s,./!+-]*$/])}
              />
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-3 mt-4 row display-flex-start">
            <button
            disabled = {buffer}
            onClick={()=>handleSubmit()} className="btn-navbar-converse w-auto fs-slight-small-css">
              {buffer===true ? "Submitting..." : "SUBMIT"}
            </button>
          </div>
        </motion.div>

        <motion.div
        // initial={{opacity:0 , x:400}}
        // whileInView={{opacity:1 , x:0}}
        // transition={{duration:1.5 , damping:200}} 
        className="py-5 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 row display-flex">
          <div className="fs-large-css fw-bold color-theme-black col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
            What Our Customers say
          </div>

          <div className="mt-3 bg-theme-grey  px-3 py-4 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row display-flex">
          <Carousel 
                value={testimonialsData} 
                itemTemplate={productTemplate} 
                numVisible={1} 
                numScroll={1} 
                responsiveOptions={responsiveOptions} 
                circular={true}
                autoplayInterval={4000} 
            /> 
          </div>

        </motion.div>
      </div>
    </div>
  );
}
