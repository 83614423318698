import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function Navbar(props) {
  const [navbarToggle, setNavbarToggle] = useState("scale-x-0");
  const [productsToggle, setProductsToggle] = useState("scale-y-0");
  const navigate = useNavigate();
  
  const navbarRef = useRef(null);
  // const [serviceOptionsStatus, setServiceOptionsStatus] = useState("scale-y-0");

  const handleClickOutside = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      setProductsToggle("scale-y-0");
    }}

    useEffect(() => {
      if (productsToggle==="scale-y-1") {
        document.addEventListener('click', handleClickOutside, true);
      } else {
        document.removeEventListener('click', handleClickOutside, true);
      }
  
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }, [productsToggle]);
  return (
    <>
      <div className="display-on-large-screen navbar-position pt-3  col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
        <div className="pb-2 col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 px-0 row m-0 display-flex-between">
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2 row px-0 m-0 display-flex">
            <img src="https://soltechindia.co.in/images/logo.png" className=" px-0 w-100 m-0" alt="" />
          </div>

          <div className="col-12 col-sm-12 col-md-9 col-lg-8 col-xl-7 row px-0 display-flex-end">
            
            <div className="col-12 col-sm-12 col-md-4 m-0 col-lg-4 col-xl-4 row px-0 display-flex-align-items-top">
              <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 row px-0 display-flex">
                <i className="fa-solid fa-phone fs-large-css"></i>
              </div>
              <div className="fs-slight-small-css col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 row px-0 display-flex">
                <div className="fw-normal col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                  Phone
                </div>
                <div className="fw-bold mt-1 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                  +91 - 7276507979
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-4 m-0  col-lg-4 col-xl-4 row px-0 display-flex-align-items-top">
              <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 row px-0 display-flex">
                <i className="fa-regular fa-envelope fs-large-css"></i>
              </div>
              <div className="fs-slight-small-css col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 row px-0 display-flex">
                <div className="fw-normal col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                  Email
                </div>
                <div className="fw-bold mt-1 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                  info@soltechindia.co.in
                </div>
              </div>
            </div>
          </div>
        </div>
<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex bg-theme-orange">
<div className="col-12 col-sm-12 col-md-11 col-lg-11 col-xl-11 row px-0 display-flex">
  <div className="height-navbar col-11 col-sm-11 col-md-3 col-lg-3 col-xl-3 row px-0 display-flex-start text-white fw-bold">
      LUBRICANTS & GREASES
  </div>
  
    <div className="height-navbar col-11 col-sm-11 col-md-9 col-lg-9 col-xl-9 row px-0 display-flex-end">
        <div className="nav-btn-container px-0 display-flex-column">
          <div className="w-100 h-100">
            <button onClick={()=>navigate("")} className="btn-navbar w-100 px-0 m-0">HOME</button>
          </div>
        </div>
        <div className="nav-btn-container px-0 display-flex-column">
          <div className="w-100 h-100">
            <button onClick={()=>navigate("/aboutus")}  className="btn-navbar w-100 px-0 m-0">ABOUT</button>
          </div>
        </div>
  
        <div className="nav-btn-container px-0 ">
          <div className="w-100 height-navbar ">
            <button
            // onMouseEnter={() => {
            //   if (productsToggle === "scale-y-0") {
            //     setProductsToggle("scale-y-1");
            //   } else {
            //     setProductsToggle("scale-y-0");
            //   }
            // }}
            onClick={()=>{
              if(productsToggle==="scale-y-0")
              {
                setProductsToggle("scale-y-1");
              }
              else
              {
                setProductsToggle("scale-y-0");
              }
            }} className="btn-navbar w-100 height-navbar px-0 m-0">PRODUCTS 
            {/* <i className="fa-solid fa-angle-down w-auto"></i> */}
            </button>
          </div>
          <div ref={navbarRef}  className={`${productsToggle} products-dropdown fs-slight-small-css display-flex-column`}>
  
            <Link
            onClick={()=>{
              setProductsToggle("scale-y-0");
            }}
            className="btn-navbar-converse-mod w-100 text-decoration-none"
            to = {{
              pathname : `/productDetails/metal-working-fluids`}}
            >Metal Working Fluid</Link>
  
            <Link
            onClick={()=>{
              setProductsToggle("scale-y-0");
            }}
            className="btn-navbar-converse-mod w-100 text-decoration-none"
            to = {{
                    pathname : `/productDetails/neat-cutting-oil`
                  }}
            >
              Neat Cutting Oil
            </Link>
  
            <Link
            onClick={()=>{
              setProductsToggle("scale-y-0");
            }}
            className="btn-navbar-converse-mod w-100 text-decoration-none"
            to = {{
                    pathname : `/productDetails/hydraulic-oil`
                  }}
            >
              Hydraulic Oil
            </Link>
  
            <Link
            onClick={()=>{
              setProductsToggle("scale-y-0");
            }}
            className="btn-navbar-converse-mod w-100 text-decoration-none"
            to = {{
                    pathname : `/productDetails/gear-oil`
                  }}
            >
              Gear Oil
            </Link>
  
            {/* <Link
            onClick={()=>{
              setProductsToggle("scale-y-0");
            }}
            className="btn-navbar-converse-mod w-100 text-decoration-none"
            to = {{
                    pathname : `/productDetails/pressure-die-casting-lubricants`
                  }}
            >
              Pressure Die-Casting Lubricants
            </Link> */}
  
            <Link
            onClick={()=>{
              setProductsToggle("scale-y-0");
            }}
            className="btn-navbar-converse-mod w-100 text-decoration-none"
            to = {{
                    pathname : `/productDetails/waylube-oil`
                  }}
            >
              WayLube Oil
            </Link>
  
            <Link
            onClick={()=>{
              setProductsToggle("scale-y-0");
            }}
            className="btn-navbar-converse-mod w-100 text-decoration-none"
            to = {{
                    pathname : `/productDetails/edm-and-carrier-oil`
                  }}
            >
              EDM & Carrier Oil
            </Link>
            {/* <Link
            onClick={()=>{
              setProductsToggle("scale-y-0");
            }}
            className="btn-navbar-converse-mod w-100 text-decoration-none"
            to = {{
                    pathname : `/productDetails/pressure-die-casting-lubricants`
                  }}
            >
              Pressure Die-Casting Lubricants
            </Link> */}
            <Link
            onClick={()=>{
              setProductsToggle("scale-y-0");
            }}
            className="btn-navbar-converse-mod w-100 text-decoration-none"
            to = {{
                    pathname : `/productDetails/rust-preventive-solutions`
                  }}
            >
              Rust Preventive Solutions
            </Link>
            <Link
            onClick={()=>{
              setProductsToggle("scale-y-0");
            }}
            className="btn-navbar-converse-mod w-100 text-decoration-none"
            to = {{
                    pathname : `/productDetails/rust-remover-solutions`
                  }}
            >
              Rust Remover Solutions
            </Link>
            <Link
            onClick={()=>{
              setProductsToggle("scale-y-0");
            }}
            className="btn-navbar-converse-mod w-100 text-decoration-none"
            to = {{
                    pathname : `/productDetails/cleaners-and-degreasers`
                  }}
            >
              Cleaners & Degreasers
            </Link>
            <Link
            onClick={()=>{
              setProductsToggle("scale-y-0");
            }}
            className="btn-navbar-converse-mod w-100 text-decoration-none"
            to = {{
                    pathname : `/productDetails/quenching-oil`
                  }}
            >
              Quenching Oil
            </Link>
            <Link
            onClick={()=>{
              setProductsToggle("scale-y-0");
            }}
            className="btn-navbar-converse-mod w-100 text-decoration-none"
            to = {{
                    pathname : `/productDetails/speciality-lubricants`
                  }}
            >
              Speciality Lubricants
            </Link>
  
            <Link
            onClick={()=>{
              setProductsToggle("scale-y-0");
            }}
            className="btn-navbar-converse-mod w-100 text-decoration-none"
            to = {{
                    pathname : `/productDetails/greases`
                  }}
            >
             Greases
            </Link>
          </div>
        </div>
  
        <div className="nav-btn-container px-0 display-flex-column">
          <div className="w-100 h-100">
            <button onClick={()=>navigate("/careers")}  className="btn-navbar w-100 px-0 m-0">CAREERS</button>
          </div>
        </div>
  
        <div className="nav-btn-container px-0 display-flex-column">
          <div className="w-100 h-100">
            <button onClick={()=>navigate("/contactus")}  className="btn-navbar w-100 px-0 m-0">CONTACT</button>
          </div>
        </div>
    </div>
</div>
</div>
      </div>

      <div className="z-index-99 py-2 bg-theme-white col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-on-small-screen">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row ps-2 pe-0 display-flex-between ">
          <div className="m-0 col-8 col-sm-5 col-md-5 col-lg-8 col-xl-8 row px-0 display-flex-start">
            <button
              onClick={() => setNavbarToggle("scale-x-1")}
              className="btn-navbar  w-auto"
            >
              <i className="fa-solid fa-bars-staggered"></i>
            </button>
            <img
              src="https://soltechindia.co.in/images/logo.png"
              alt="Soltech LOGO"
              className="w-75 px-2"
            />
          </div>
        </div>

        <div
          className={`height-100vh col-10 small-screen-navbar ${navbarToggle} col-sm-10 col-md-8 col-lg-6 py-4 m-0 col-xl-12 row px-4 display-flex-align-items-top`}
        >
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-end">
            <button
              onClick={() => setNavbarToggle("scale-x-0")}
              className="btn-navbar  w-auto"
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>

          <div className="w-100 mt-0 px-0 display-flex-column">
            <button
              onClick={() => {
                navigate("/");
                setNavbarToggle("scale-x-0");
              }}
              className="btn-navbar mt-2 w-auto"
            >
              Home
            </button>
            <button
              onClick={() => {
                navigate("/aboutus");
                setNavbarToggle("scale-x-0");
              }}
              className="btn-navbar mt-3 w-auto"
            >
              About Us
            </button>
            <button onClick={() => {navigate("/all-products");
                setNavbarToggle("scale-x-0");}} className="btn-navbar mt-3 w-auto">
              All Products
              {/* <i className={`ms-2 fa-solid fa-angle-down `}></i> */}
            </button>
            <button
              onClick={() => {
                navigate("/careers");
                setNavbarToggle("scale-x-0");
              }}
              className="btn-navbar mt-3 w-auto"
            >
              Careers
            </button>
            <button
              onClick={() => {
                navigate("/contactus");
                setNavbarToggle("scale-x-0");
              }}
              className="btn-navbar mt-3 w-auto"
            >
              Contact
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
