import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

export default function LandingPage() {
    const [currentIndex , setCurrentIndex] = useState(0);
    const slides = [
        "mainpage-bg-image-1.jpeg",
        "mainpage-bg-image-2.jpg",
        "mainpage-bg-image-3.jpg",
    ];

    useEffect(()=>{
        const intervalId = setInterval(()=>{
            setCurrentIndex(prevIndex => (prevIndex+1) % 3);
        },3000);

        return () => clearInterval(intervalId);
    },[currentIndex]);
    
  return (
    <motion.div 
    initial={{opacity:0}}
    animate={{opacity:1}}
    transition={{duration: 1, damping:200}}
    className='height-60vh col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex'>
        <div className='slider h-100 w-100 px-0 m-0 display-flex'>
            {slides.map((ele, index)=>
                <img src={`https://soltechindia.co.in/images/${ele}`} alt='' className={` ${currentIndex===index ? "active" : ""} slide px-0 w-100`} />
            )}
        </div>
    </motion.div>
  )
}
