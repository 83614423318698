import React from 'react';
import Header from './Header';
import { motion } from 'framer-motion';

export default function Careers() {
    const vacancies = [
        {
            post:"Sales Executive",
            location:"Pune",
            duration:"Full Time",
            minimumExperience:"2",
            description:``,
            specification:"Only Male"
        },
        {
            post:"Accountant",
            location:"Pune",
            duration:"Full Time",
            minimumExperience:"2-3",
            description:"",
            specification:"Male / Female"
        },
        {
            post:"Telecaller",
            location:"Pune",
            duration:"Part Time",
            minimumExperience:"1-2",
            description:"",
            specification:"Only Female"
        }
        // {
        //     post:"Marketting Expert",
        //     location:"Mumbai",
        //     duration:"Full Time",
        //     minimumExperience:"2",
        //     description:""
        // }
    ];
  return (
    <motion.div
    initial={{opacity:0}}
    whileInView={{opacity:1}}
    transition={{duration:1.5 , damping:200}}
    className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex'>
        <Header title={"Careers"} />
        <motion.div 
    initial={{opacity:0 , y:200}}
    whileInView={{opacity:1 , y:0}}
    transition={{duration:1.5 , damping:200}} className='col-11 mt-5 p-5 col-sm-11 col-md-11 col-lg-11 col-xl-11 row bg-theme-grey display-flex'>
            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex fw-bold fs-larger-css text-center color-theme-black'>
                We're Hiring!
            </div>

            <div className='mt-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex'>
                <div className='fw-normal col-12 col-sm-12 col-md-8 col-lg-8 col-xl-6 row px-0 text-center'>
                Step into a world of opportunities where innovation meets ambition. Join our dynamic team, make a meaningful impact, and elevate your career to new heights. Discover your potential with us and be a part of something extraordinary
                </div>
            </div>

            
        </motion.div>

        <motion.div 
    initial={{opacity:0 , y:200}}
    whileInView={{opacity:1 , y:0}}
    transition={{duration:1.5 , damping:200}} className='py-5 col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 row px-0 display-flex-align-items-top'>
            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex fw-bold text-center fs-larger-css color-theme-black'>
                Vacancies in our <br /> TEAM
            </div>

            {vacancies.map((ele,index)=>
                <div key={index} className='px-2 col-11 col-sm-11 col-md-11 col-lg-4 col-xl-4 row display-flex mt-4'>
                <div className="border-grey py-4  col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-4 display-flex">
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start fw-bold fs-medium-css'>
                            {ele.post}
                    </div>
                    <div className='mt-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start fw-normal color-theme-black fs-slight-small-css'>
                    <span className={`${window.innerWidth<600 ? "w-100": "w-auto"} display-flex-start px-0 mx-0 mt-2`}>
                        <i className={`fa-solid fa-location-dot color-theme-orange w-auto me-1 ms-0 px-0 `}></i> {ele.location}
                    </span>
                    <span className={`${window.innerWidth<600 ? "w-100": "w-auto"} display-flex-start px-0 mx-0 mt-2`}>
                        <i className={`fa-solid fa-clock color-theme-orange w-auto me-1 ${window.innerWidth>600 && "ms-4"} px-0`}></i> {ele.duration}
                    </span>
                    <span className={`${window.innerWidth<600 ? "w-100": "w-auto"} display-flex-start px-0 mx-0 mt-2`}>
                        <i className={`fa-solid fa-brain color-theme-orange w-auto me-1 ${window.innerWidth>600 && "ms-4"} px-0`}></i> {ele.minimumExperience!=="0" && ele.minimumExperience} {ele.minimumExperience!=="0" ? "+ years experience" : "Fresher"}
                    </span>
                    </div>

                    <div className="mt-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                            Specifications: {ele.specification} 
                    </div>
                    <div className='mt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start'>
                        {/* <div className='col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 row px-0 display-flex text-align-justify fw-normal color-theme-black'>
                                {ele.description}
                        </div> */}
                        <div className='col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 mx-0 row px-0 display-flex-start'>
                            <a href='mailto:sachinkale@soltechindia.co.in' target='_blank' className={`${(index+1)%2===0 ? "btn-navbar" : "btn-navbar-converse"} text-decoration-none mx-0 w-auto`}>Apply</a>
                        </div>
                    </div>
                </div>
            </div>
            )}
        </motion.div>


        

    </motion.div>
  )
}
