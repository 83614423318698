import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

export default function Footer() {
    const navigate = useNavigate();
  return (
    <motion.div 
    initial={{opacity:0 , y:200}}
    whileInView={{opacity:1 , y:0}}
    transition={{duration:1.5 , damping:200}}
    className="py-0 bg-theme-grey col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
      
      <div className="col-12 mt-0 col-sm-12 col-md-12 col-lg-10 col-xl-10 row px-0 display-flex">
        
        <div className="mt-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-align-items-top">
          <div className="mt-4 col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 row px-4 color-theme-white display-flex">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
              <img
                src="https://soltechindia.co.in/images/logo.png"
                alt=""
                className="px-0 w-100"
              />
            </div>

            <div className="mt-4 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start fw-light">
            Manufacturer of Industrial Lubricants and Greases.
            </div>

            <div className="mt-4 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
              <a target="_blank" rel="noreferrer" href='https://www.facebook.com/profile.php?id=61566030360457' className="btn-footer-icon text-decoration-none">
                {" "}
                <i className="fa-brands fa-facebook-f"></i>{" "}
              </a>
              <a target="_blank" rel="noreferrer" href='https://wa.me/7066220219' className="btn-footer-icon text-decoration-none">
                {" "}
                <i className="fa-brands fa-whatsapp"></i>{" "}
              </a>
              <a target="_blank" rel="noreferrer" href='https://www.youtube.com/@soltech-u2z' className="btn-footer-icon text-decoration-none">
                {" "}
                <i className="fa-brands fa-youtube"></i>{" "}
              </a>
              <a target="_blank" rel="noreferrer" href='https://x.com/soltech150' className="btn-footer-icon ext-decoration-none">
                {" "}
                <i className="fa-brands fa-x-twitter"></i>{" "}
              </a>
              <a target="_blank" rel="noreferrer" href='https://www.instagram.com/soltech150/' className="btn-footer-icon text-decoration-none">
                {" "}
                <i className="fa-brands fa-instagram"></i>{" "}
              </a>

              <a target="_blank" rel="noreferrer" href='https://www.linkedin.com/company/soltech-industrial-lubricants-greases' className="btn-footer-icon text-decoration-none">
                {" "}
                <i className="fa-brands fa-linkedin"></i>{" "}
              </a>
            </div>
          </div>

          <div className="mt-4 col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 row px-4 color-theme-white display-flex-align-items-top">
            <div className="fw-normal fs-medium-css col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
              Important Links
            </div>

            <div className="mt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
              <div className="mt-4 col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 px-0 display-flex-column">
                <span className="w-100 display-flex-start">
                  
                  <button onClick={()=>navigate("")} className="btn-footer fw-normal">Home </button>
                </span>

                <span className="mt-3 w-100 display-flex-start">
                  
                  <button onClick={()=>navigate("/aboutus")}  className="btn-footer fw-normal">About Us </button>
                </span>

                <span className="mt-3 w-100 display-flex-start">
                  
                  <button onClick={()=>navigate("/products")}  className="btn-footer fw-normal">Products </button>
                </span>    

                <span className="mt-3 w-100 display-flex-start">
                  
                  <button onClick={()=>navigate("/contactus")}  className="btn-footer fw-normal">Contact Us </button>
                </span>           
              </div>
            </div>

            
          </div>

          <div className="mt-4 col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 row px-4 color-theme-white display-flex-align-items-top">
            <div className="fw-normal fs-medium-css col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
              Contact Info
            </div>

            <div className=" mt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-align-items-top">
              <div className=" col-4 fs-medium-css color-theme-white col-sm-4 col-md-4 col-lg-4 col-xl-4 row pe-3 ps-0 display-flex-end ">
                <button className="btn-footer-icon ">
                <i class="fa-solid fa-phone"></i>
                </button>
              </div>
              <div className="color-theme-white col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 row px-0 display-flex-align-items-top">
                {/* <div className="fw-bold col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                Phone
                </div> */}
                <div className="mt-0 fw-light col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                +91- 7066220219
                </div>
              </div>
            </div>

            <div className="mt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-align-items-top">
              <div className="col-4 fs-medium-css color-theme-white col-sm-4 col-md-4 col-lg-4 col-xl-4 row pe-3 ps-0 display-flex-end ">
              <button className="btn-footer-icon">
                <i class="fa-solid fa-envelope"></i>
                </button>
              </div>
              <div className="color-theme-white col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 row px-0 display-flex-align-items-top">
                {/* <div className="fw-bold col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
               Email
                </div> */}
                <div className="mt-0 fw-light col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                info@soltechindia.co.in
                </div>
              </div>
            </div>

            <div className="mt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-align-items-top">
              <div className="col-4 fs-medium-css color-theme-white col-sm-4 col-md-4 col-lg-4 col-xl-4 row pe-3 ps-0 display-flex-end ">
              <button className="btn-footer-icon">
              <i class="fa-solid fa-location-dot"></i>
                </button>
              </div>
              <div className="color-theme-white col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 row px-0 display-flex-align-items-top">
                <div className="mt-0 fw-light col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                Shed No 01, Manjula
Warehouse, Sr.No.38/4,
Khedekar Industrial Estate, Dhayari-Narhe Rd, Pune-411041, 
Maharashtra 
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5 py-4  fw-normal color-theme-white border-top-theme-blue col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-center">
          © 2024 All rights reserved. Designed & Developed at <span className="ms-1 me-0 px-0 w-auto fw-bold color-theme-blue">Growth Studio</span> .
        </div>
      </div>


    </motion.div>
  )
}
