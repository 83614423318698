import React, { useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

export default function OurProducts() {
  // const navigate = useNavigate();
  const productsData = [
    [
      {
        image: "https://soltechindia.co.in/images/metal-working-fluid.jpg",
        title: "Metal Working Fluids",
        description:
          "SOLTECH  offers a wide range of metalworking fluids including synthetic, semi synthetic and mineral base metal working fluids",
        hrefLink: "/productDetails/metal-working-fluids",
      },
      {
        image: "https://soltechindia.co.in/images/metal-forming-oil.jpg",
        title: "Neat Cutting Oil",
        description:
          "SOLTECH  offers a full range of neat cutting oils and metal forming oils designed to meet the rigorous demands of machining and metal forming processes.",
        hrefLink: "/productDetails/neat-cutting-oil",
      },
      {
        image: "https://soltechindia.co.in/images/hydraulic-oil.jpg",
        title: "Hydraulic Oil",
        description:
          "Hydraulic oil from SOLTECH  is a premium-grade lubricant specially formulated for hydraulic systems. With superior anti-wear properties and excellent thermal stabilit",
        hrefLink: "/productDetails/hydraulic-oil",
      },
    ],
    [
      {
        image: "https://soltechindia.co.in/images/hydraulic-and-gear-oil.webp",
        title: "Gear Oil",
        description:
          "Drawing lubricants are designed to facilitate the smooth movement of metal sheets or blanks through dies during drawing operations.",
        hrefLink: "/productDetails/gear-oil",
      },
      {
        image: "https://soltechindia.co.in/images/edm-oil-product.jpg",
        title: "EDM & Carrier Oil",
        description:
          "SOLTECH  offers specialized EDM (Electrical Discharge Machining) machine oil tailored to meet the exacting requirements of EDM operations.",
        hrefLink: "/productDetails/edm-and-carrier-oil",
      },
      {
        image: "https://soltechindia.co.in/images/drawing-and-stamping-lubricants.jpg",
        title: "WayLube Oil",
        description:
          "WayLube Oils are designed to facilitate the smooth movement of metal sheets or blanks through dies during drawing operations.",
        hrefLink: "/productDetails/waylube-oil",
      },
    ],
    [
      {
        image: "https://soltechindia.co.in/images/rust-preventive-solutions.jpg",
        title: "Rust Preventive Solutions",
        description:
          "SOLTECH manufactures rust preventive oils and solutions to protect metals from rust and corrosion.",
        hrefLink: "/productDetails/rust-preventive-solutions",
      },
      {
        image: "https://soltechindia.co.in/images/rust-removal-solutions.jpg",
        title: "Rust Remover Solutions",
        description:
          "SOLTECH  Pvt Ltd offers a range of rust remover chemicals designed for industrial applications to effectively remove rust from components.",
        hrefLink: "/productDetails/rust-remover-solutions",
      },
      {
        image: "https://soltechindia.co.in/images/cleaners-and-degreasers.jpeg",
        title: "Cleaners & Degreasers",
        description:
          "SOLTECH  Pvt. Ltd. specializes in high-performance cleaners and degreasers for industrial use.",
        hrefLink: "/productDetails/cleaners-and-degreasers",
      },
    ],
    [
      {
        image: "https://soltechindia.co.in/images/quenching-oil.webp",
        title: "Quenching Oil",
        description:
          "SOLTECH  offers SOLTECH  offers a premium range of quenching oils engineered to meet the rigorous demands of heat treatment processes.",
        hrefLink: "/productDetails/quenching-oil",
      },
      {
        image: "https://soltechindia.co.in/images/speciality-lubricants.jpg",
        title: "Speciality Lubricants",
        description:
          "SOLTECH  Pvt Ltd offers a comprehensive range of specialty lubricants designed to meet the specific needs of various industrial applications.",
        hrefLink: "/productDetails/speciality-lubricants",
      },
      {
        image: "https://soltechindia.co.in/images/greases.jpg",
        title: "Greases",
        description:
          `Grease is a solid or semisolid lubricant formed as a dispersion of thickening agents in a liquid lubricant. Grease generally consists of a soap emulsified with mineral or vegetable oil.`,
        hrefLink: "/productDetails/greases",
      },
    ],
  ];
  const navigate = useNavigate();

  const [currentIndex, setCurrentIndex] = useState(0);
  return (
    <div className="mt-5 py-5  bg-theme-black col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
      <motion.div
    initial={{opacity:0, y:200}}
    whileInView={{opacity:1, y:0}}
    transition={{duration:1.5, damping:200}} className="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 row px-0 display-flex">
        <div className="fs-large-css fw-bold text-white col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 row px-0 display-flex-start">
          Our Products
        </div>

        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 row px-0 display-flex-end">
          <button
            onClick={() => {
              setCurrentIndex((prevIndex) => prevIndex - 1);
            }}
            className="btn-carousel rounded-circle"
            disabled={currentIndex === 0 ? true : false}
          >
            <i className="fa-solid fa-arrow-left-long"></i>
          </button>
          <button
            onClick={() => {
              setCurrentIndex((prevIndex) => prevIndex + 1);
            }}
            className="btn-carousel rounded-circle"
            disabled={currentIndex === productsData.length - 1 ? true : false}
          >
            <i className="fa-solid fa-arrow-right-long"></i>
          </button>
        </div>
      </motion.div>
      {/* <div className="mt-5 pb-5 myborder col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 m-0 row px-0 display-flex"> */}
        <motion.div
    initial={{opacity:0, y:200}}
    whileInView={{opacity:1, y:0}}
    transition={{duration:1.5, damping:200}} className="my-5 pb-5 slider-products col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 m-0 display-flex">
          {productsData.map((slide, index) => (
            <div key={index} className={`${
                currentIndex === index ? "active" : ""
              } slide-products bg-theme-black pb-5 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 m-0 display-flex-align-items-top`}>
              {slide.map((ele, index) => (
                <div
                  key={index}
                  className={`mt-5 col-10 col-sm-10 col-md-4 col-lg-4 col-xl-4 row px-3 display-flex`}
                >
                  <div className="bg-white col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-align-items-top min-height-80vh">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
                      <img src={ele.image} alt="" className="product-image-homepage px-0 " />
                    </div>

                    <div className="mt-4 fw-bold fs-medium-css w-100 px-4 display-flex-start">
                      {ele.title}
                    </div>

                    <div className="mt-3 fw-normal fs-slight-small-css w-100 px-4 display-flex-start">
                      {ele.description}
                    </div>

                    <div className="mt-3 mb-5 fw-normal fs-slight-small-css w-100 px-4 display-flex-start">
                      <button onClick={()=>navigate(ele.hrefLink)} className="btn-navbar w-auto">Read More</button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </motion.div>
      {/* </div> */}
    </div>
  );
}
