import React, { useEffect } from 'react'
import Header from './Header'
import ProductsContent from './ProductsContent'

export default function ProductsPage(props) {
  useEffect(()=>{
    window.scroll(0,0);
  },[]);
  return (
    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex'>
        <Header title="Products" />

        <ProductsContent product={props.product} />
      
    </div>
  )
}
