import React from 'react';
import ProfilePpt from "./soltech-company-profile.pdf";

export default function StickyButtons() {
  return (
    <div className='sticky-btn-container w-auto row px-0 display-flex-column'>
        <div className="w-100 px-0 display-flex-end">
          <a target='_blank' rel="noreferrer" href='http://wa.me/7066220219' className='text-decoration-none btn-navbar-converse w-auto'>
          <i className="fa-brands fa-whatsapp"></i>
          </a>
        </div>
        <div className="w-100 px-0 display-flex-end">
          <a target='_blank' rel="noreferrer" href='mailto:info@soltechindia.co.in?subject=Enquiry for Oils&body=Sample Enquiry' className='text-decoration-none mt-3 btn-navbar-converse w-auto'>
          <i className="fa-solid fa-envelope"></i>
          </a>
        </div>


        <a href={ProfilePpt} download={"SOLTECH COMPANY PROFILE"} className='text-decoration-none mt-3 btn-navbar-converse w-auto'>
        Download Brochure <i class="fa-solid fa-download"></i>
        </a>
    </div>
  )
}
