import React, { useEffect } from 'react'
import Header from './Header'
import AboutUsContent from './AboutUsContent';
import {motion} from "framer-motion";

export default function AboutUsPage() {
  useEffect(()=>{
    window.scroll(0,0);
  },[]);
  return (
    <motion.div
    initial={{opacity:0}}
    whileInView={{opacity:1}}
    transition={{duration:1.5, damping:200}}
    className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex'>
        <Header title="About Us" />

        <AboutUsContent />
    </motion.div>
  )
}
