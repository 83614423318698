import React, { useState } from 'react';
import { motion } from 'framer-motion';

export default function ContactUsContent() {

  const [formData , setFormData] = useState({});
  const [buffer, setBuffer] = useState(false);

  function validateInputPattern(event, regex) {
    if (event.target.value !== "") {
      document
            .getElementsByName(event.target.name)[0]
            .classList.remove("compulsory");
      for (var i = 0; i < regex.length; i++) {
        if (regex[i].test(event.target.value) === true) {
          document
            .getElementsByName(event.target.name)[0]
            .classList.remove("wrong-input");
          setFormData({
            ...formData,
            [event.target.name]: event.target.value,
          });
          break;
        } else if (regex[i].test(event.target.value) === false) {
          document
            .getElementsByName(event.target.name)[0]
            .classList.add("wrong-input");
        }
      }
    }
    else
    {
      document
            .getElementsByName(event.target.name)[0]
            .classList.add("compulsory");
    }
  }

  async function handleSubmit()
  {
    try {
      setBuffer(true);
      const input_fields = document.getElementsByClassName("input-field");
      let wrong_field = "";
      let empty_field = "";
      for(var i=0;i<input_fields.length;i++)
      {
        for(var j=0;j<input_fields[i].classList.length;j++)
        {
          if(input_fields[i].classList[j]==="wrong-input")
          {
            wrong_field = input_fields[i].name ;
            break;
          }
          else if(input_fields[i].classList[j]==="compulsory")
          {
            empty_field = input_fields[i].name;
            break;
          }
          else
          {
            wrong_field = "" ;
            empty_field = "" ;
          }
        }

        if(wrong_field!=="")
        {
          alert(`Invalid Format of ${wrong_field.toUpperCase()}!`);
          document.getElementsByName(wrong_field)[0].focus();
          setBuffer(false);
          break;
        }
        else if(empty_field!=="")
        {
          alert(`${empty_field.toUpperCase()} is mandatory !`);
          document.getElementsByName(empty_field)[0].focus();
          setBuffer(false);
          break;
        }
      }

      if(wrong_field==="" && empty_field==="")
      {
        setBuffer(true);
        await fetch("https://soltechindia.co.in/sendContact.php" ,{
          method : "post",
          body : JSON.stringify(formData)
        })
        .then(async(res)=>{
          res = await res.json();
          if(res.success)
          {
            alert(res.success);
          }
          else
          {
            alert(res.error);
          }
          setBuffer(false);
          window.location.reload();
        })
        .catch(err=>{
          alert("Something went wrong, please check your internet connectivity");
          setBuffer(false);
        })
      }

      

    } catch (error) {
      alert("Error while communicating with server!");
      setBuffer(false);
    }
  }
  return (
    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
      <div className="mt-0 py-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-align-items-top">
        

        <motion.div 
    initial={{opacity:0 , x:-200}}
    whileInView={{opacity:1 , x:0}}
    transition={{duration:1.5 , damping:200}} className="py-5 bg-theme-grey col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 row px-5 display-flex">
            <div className="fs-large-css fw-bold color-theme-black col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                Contact Us
            </div>
            <div className="mt-4 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
              <input
                type="text"
                className="mt-34 input-field compulsory w-100"
                placeholder="Your Name"
                name="name"
                onChange={(event)=>validateInputPattern(event , [/^[a-zA-Z\s]+$/])}
              />
            </div>

            <div className="mt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
              <input
                type="text"
                className="mt-3 input-field compulsory w-100"
                placeholder="Phone"
                maxLength={10}
                name="phone"
                onChange={(event)=>validateInputPattern(event , [/^\d{10}$/])}
              />
            </div>

            <div className="mt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
              <input
                type="text"
                className="mt-4 input-field compulsory w-100"
                placeholder="Type Of Service"
                name="typeOfService"
                onChange={(event)=>validateInputPattern(event , [/^[A-Za-z0-9\s,./!+-]*$/])}
              />
            </div>

            <div className="mt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
              <textarea
                type="text"
                className="mt-4 input-field compulsory w-100"
                placeholder="Message"
                name="message"
                onChange={(event)=>validateInputPattern(event , [/^[A-Za-z0-9\s,./!+-]*$/])}
              />
            </div>

            <div className="mt-5 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                <button
                onClick={handleSubmit}
                className="btn-navbar-converse w-auto">
                    {buffer===false ? "Reach" : "Reaching..."}
                </button>
            </div>
        </motion.div>

        <motion.div 
    initial={{opacity:0 , x:200}}
    whileInView={{opacity:1 , x:0}}
    transition={{duration:1.5 , damping:200}} className="py-5 col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 row px-4 display-flex">
          <div className="fs-larger-css fw-bold color-theme-black col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
            Get In Touch
          </div>
          <div className="w-75 px-0 mt-4  display-flex-start me-auto">
            <div className="px-0 m-0 line-theme-blue "></div>
          </div>

          <div className="mt-5 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-align-items-top">
            <div className="w-auto row px-0 display-flex">
              <span className="color-theme-orange fs-large-css rounded-circle display-flex">
                <i className="fa-solid fa-location-dot"></i>
              </span>
            </div>

            <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 row px-0 display-flex">
                <div className="fw-bold col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                Address
                </div>
                <div className="fw-normal mt-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                Shed No 01, Manjula
Warehouse, Sr.No.38/4,
Khedekar Industrial Estate, Dhayari-Narhe Rd, Pune-411041, 
Maharashtra                </div>
            </div>
          </div>

          <div className="mt-5 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-align-items-top">
            <div className="w-auto row px-0 display-flex">
              <span className="color-theme-orange fs-large-css rounded-circle display-flex">
                <i className="fa-solid fa-phone"></i>
              </span>
            </div>

            <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 row px-0 display-flex">
                <div className="fw-bold col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                Phone
                </div>
                <div className="fw-normal mt-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                +91 7276507979 
                </div>
                <div className="mt-2 fw-normal mt-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                 +91 7066220219
                </div>
            </div>
          </div>

          <div className="mt-5 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-align-items-top">
            <div className="w-auto row px-0 display-flex">
              <span className="color-theme-orange fs-large-css rounded-circle display-flex">
                <i className="fa-solid fa-envelope"></i>
              </span>
            </div>

            <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 row px-0 display-flex">
                <div className="fw-bold col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                Email
                </div>
                <div className="fw-normal mt-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                info@soltechindia.co.in
                </div>
                <div className="mt-2 fw-normal mt-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                sachinkale@soltechindia.co.in
                </div>
            </div>
          </div>
        </motion.div>
      </div>

      <motion.div 
    initial={{opacity:0 , y:200}}
    whileInView={{opacity:1 , y:0}}
    transition={{duration:1.5 , damping:200}} className="mt-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
      <iframe title='Map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3784.839488904606!2d73.82137827393368!3d18.4455955714627!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2953d5851c1a9%3A0x2caa0f5392d29f71!2sULTIMATE%20SOLUTION!5e0!3m2!1sen!2sin!4v1726736359050!5m2!1sen!2sin" className="height-60vh px-0" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"/>

      {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3784.839488904606!2d73.82137827393368!3d18.4455955714627!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2953d5851c1a9%3A0x2caa0f5392d29f71!2sULTIMATE%20SOLUTION!5e0!3m2!1sen!2sin!4v1726736359050!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
      </motion.div>

    </div>
  )
}
